import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'cbt_summary';
const namespace = 'ui-pdp-icon ui-pdp-icon--cbt-summary';

const IconCBTSummary = ({ className, description }) => (
  <svg
    className={classnames(namespace, className)}
    width="190"
    height="12"
    viewBox="0 0 190 12"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby={`${ICON_ID}--title`}
  >
    {description && <title id={`${ICON_ID}--title`}>{description}</title>}
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconCBTSummary.propTypes = {
  className: string,
  description: string,
};

IconCBTSummary.defaultProps = {
  className: null,
  description: '',
};

IconCBTSummary.ICON_ID = ICON_ID;

export default React.memo(IconCBTSummary);
export { IconCBTSummary };
